@import "globals.scss";
/* stylelint-disable scss/no-global-function-names */
.emoji-reaction {
  position: absolute;
  border-radius: 30px;
  width: 36px;
  height: 36px;
  margin-left: -30px;
  bottom: 0px;
}

.emoji-animation-horizontal {
  bottom: 0;
  left: 15px;
  position: absolute;
}

.emoji-like {
  background-color: #6447b0;
  border: 1px solid #b8a8fc;
}

.emoji-clap {
  background-color: #9a3089;
  border: 1px solid #eda3ff;
}

.emoji-confused {
  background-color: #31417e;
  border: 1px solid #b7c6ff;
}

.emoji-rainbow {
  background-color: #31417e;
  border: 1px solid #b7c6ff;
}

.emoji-image {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.emoji-animation {
  @for $i from 1 through 30 {
    $p0: calc(0.25 + random() / 6);
    $p1: calc(0.45 + random() / 4);
    $p2: calc(0.65 + random() / 4);
    $p3: calc(0.9 + random() / 12);
    $transform-y-deviation: -100px * random();

    @keyframes vertical-animation-#{$i} {
      0% {
        opacity: 0;
      }

      20% {
        opacity: 1;
      }

      80% {
        opacity: 1;
      }

      100% {
        transform: translateY(calc(-50vh + #{$transform-y-deviation}));
        opacity: 0;
      }
    }

    @keyframes horizontal-animation-#{$i} {
      0% {
        @if $i < 10 {
          transform: translateX(-10px + random(30));
        } @else {
          transform: translateX(-30px + random(10));
        }
      }

      100% {
        @if $i < 10 {
          transform: translateX(-30px + random(10));
        } @else {
          transform: translateX(-10px + random(30));
        }
      }
    }

    /* stylelint-disable-next-line scss/dollar-variable-pattern */
    $cubicBezier: cubic-bezier($p0, $p1, $p2, $p3);
    /* stylelint-disable-next-line scss/dollar-variable-pattern */
    $verticalAnimationDuration: 3.8s;
    /* stylelint-disable-next-line scss/dollar-variable-pattern */
    $horizontalAnimationDuration: 0.8s + random();
    /* stylelint-disable-next-line scss/dollar-variable-pattern */
    $horizontalIterations: calc(
      $verticalAnimationDuration / $horizontalAnimationDuration
    );

    &-vertical-#{$i} {
      animation: vertical-animation-#{$i}
        $verticalAnimationDuration
        $cubicBezier
        1
        forwards;
    }

    &-horizontal-#{$i} {
      animation: horizontal-animation-#{$i}
        $horizontalAnimationDuration
        ease-in-out
        alternate
        $horizontalIterations;
    }
  }
}
