@import "globals.scss";
.emoji-reaction-container {
  pointer-events: none;
  position: absolute;
  right: 10px;
  height: 60vh;
  bottom: 90px;
  width: 50px;
  z-index: $emojiReactionsContainerZIndex;

  &.bottom-offset {
    bottom: 140px;
    height: 55vh;
  }
}
